.hero-nav {
  height: 150px;
  background-color: rgba(218, 218, 222);
}

.hero-nav span {
  font-size: 2rem;
}

.hero-nav-col {
  padding-top: 25px;
}

.hero-nav-col a {
  color: maroon;
  text-decoration: none;
}
