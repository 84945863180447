.admin-container {
  display: flex;
}

.sidebar {
  width: 10%;
  background-color: maroon;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  min-height: 700px;
  font-size: 20px;
}

.sidebar-logo {
  height: 100px;
  align-self: center;
}

.link-container {
  text-align: left;
}

.sidebar-link {
  margin-bottom: 20px;
  cursor: pointer;
  text-align: left;
}

.sign-out-button {
  margin: 20px 0;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: maroon;
  cursor: pointer;
  font-size: 25px;
}

.content-area {
  width: 80%;
  padding: 20px;
}

.admin-page-selector {
  margin-top: 10px;
  display: block;
  padding: 5px;
}