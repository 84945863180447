@import url("./home/components/styling/carousel");
@import url("./home/components/styling/news");
@import url("./home/components/styling/competition.css");
@import url("./home/components/styling/HeroNav.css");
@import url("./home/components/styling/getInvolved.css");
@import url("./home/components/styling/bucs.css");
@import url("./Squads/styling/squads.css");
@import url("./shared/styling/NavigationBar");
@import url("./about/partners/partnerSection.css");
@import url("./about/styling/staffAndCoaching.css");
@import url("./about/styling/getInvolved.css");
@import url("./shared/styling/heroBannerHeader.css");
@import url("./shared/styling/Footer.css");

@import url("./Vacancies/Vacancy.css");

@import url("./about/styling/studentTestimonials.css");
@import url("./Admin/styles/AdminHome.css");



.App,
* {
  text-align: center;
  font-family: Gibson, canada-type-gibson, sans-serif;
}

.squads-body-text p, .squad-item-text-box-main-text, .news-item-page-text-box p, .row1 p, .testimonial-item span, .get-invovled-page-text-span span, .bucs-section-text span {
  white-space: pre-line; 
  }
  

.col2 h1,
.col-social h1 {
  padding-top: 100px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 3px solid maroon;
  border-width: 50%;
  width: 50%;
  font-weight: 900;
  color: navy;
}

.col-social h1 {
  padding-top: 150px;
  width: 60%;
}

.col2 P {
  padding-right: 80px;
  padding-left: 0px;
}

.col2 P,
.col-social p {
  text-align: left;
  font-size: 18px;
}
@media (min-width: 601px) {
  .col1-sm {
    display: none;
  }
}
@media only screen and (max-width: 765px) {
  .col1 {
    display: none;
  }
  .col2 {
    padding: 30px 2.6rem 0px;
    background-color: maroon;
  }
  .col2 h1,
  .col-social h1 {
    color: #fff;
    text-align: left;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: none;
    padding: 30px 2.3rem 0px;
    width: 100%;
  }
  .col2 P {
    padding: 30px 2.66rem;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
  }
  .nav-link {
    color: white !important;
  }

  .news-most-rec {
    margin-top: 80px !important;
  }

  .news-most-rec1 {
    margin-top: 30px !important;
  }

  .partners-patrons-contact-container {
    padding-top: 80px;
  }

  .partners-patrons-contact-container, .partners-patrons-contact h4, .partners-patrons-contact span, .partner-sections, .partner-sections .testimonial-item .col-12 span, .partner-sections .testimonial-item .col-12 h4 {
    background-color: maroon;
    color: white !important;
  }

  .partner-sections .testimoial-image{
    padding-bottom: 20px;
  }

  .partner-sections .testimonial-item {
    padding: 0px 0px;
    margin: 0px 0px;
  }

  .partner-sections .testimonial-item span {
    font-size: 16px;
  }

  .testimoial-text, .testimonial-item h4 {
    width: 95%;
    margin: auto;
    text-align: left;
  }
}

.row1 {
  min-height: 800px;
}

.col1-img {
  height: 800px;
  width: 100%;
}

.arrow-down {
  position: fixed;
  top: 50%;
  right: 1%;
  z-index: 10;
}

.section__title {
  color: navy;
  font-size: 48px;
  border-bottom: 5px solid darkred;
  text-align: left;
  padding-left: 30px;
  width: 60%;
  margin: auto;
  margin-top: 100px;
}

.section__title h1 {
  font-weight: 900;
}

.partners-patrons-contact {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
}



.partners-patrons-contact h4 {
  font-weight: 700;
  font-size: 2rem;
  color: maroon;
  text-align: left;
}

.partners-patrons-contact span {
  text-align: left;
  padding: 15px 0px;
  color: black;
  font-weight: 700;
}

.partners-patrons-contact button {
  width: auto;
  padding: 15px;
  color: white;
  background-color: maroon;
  font-weight: 700;
  border: none;
  border-radius: 25px;
  font-size: 1.2rem;
  margin-top: 20px;
}

.shop-items-containers-col h1 {
  color: maroon;
  border: 1px solid maroon;
  width: inherit;
  margin: 0 auto;
  margin-bottom: 20px;
}

.shop-items-containers-col {
  text-align: left;
  height: 100%;
  width: 100%;
  position: relative;
}

.shop-items-containers-col a {
  text-decoration: none;
}

.shop-items-containers-col span {
  color: maroon;
  font-weight: 600;
}

.shop-items-containers img {
  width: 400px;
  height: 600px;
  margin-bottom: 10px;
  object-fit: cover;
  /* aspect-ratio: 3/3; */
}

.news-card a {
  color: black;
  text-decoration: none !important;
}

.news-card:hover {
  transform: scale(1.1);
}
.news-card-body {
  height: 100px;
  text-align: left;
  padding-top: 5px;
}

.news-card-body span {
  font-size: 0.8rem;
  color: black;
  text-overflow: hidden;

}

.news-card-body h6 {
  text-align: left;
  font-weight: 800;
  color: maroon;
}

.shop-items-containers-col-box button {
  border: none;
  border-radius: 25px;
  background-color: maroon;
  color: white;
  font-weight: 600;
  padding: 0px 30px;
  font-size: 20px;
}

@media only screen and (max-width: 765px) {
  .shop-items-containers img {
    width: 100%;
    height: 546px;
    object-fit: cover;
    /* aspect-ratio: 3/3; */
  }

  .shop-container .mob-banner-title {
    background-color: maroon;
    width: 50%;
    color: white;
    margin: auto;
    margin-top: 30px;
  }

  .shop-container .mob-banner-title h1 {
    text-align: center;
    padding-bottom: 20px;
  }
}

.admin-form {
  margin-top: 1000px;
}
.admin-form form {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.admin-form form input,
.admin-form form textarea {
  margin-bottom: 10px;
}

.admin-list table,
.admin-list th,
.admin-list td {
  border: 1px solid;
}



.admin-list tr td {
  padding: 10px;
  text-align: left;
}

.admin-list h1,
.admin-list table {
  margin: auto;
  margin-bottom: 50px;
}

.hp-competitions .btn-primary {
  color: #fff;
  background-color: maroon;
  border-color: maroon;
}

.admin-home-logging {
  position: relative;
}
.admin-home-nav {
  margin: 30px 0px;
}

.admin-home-nav a {
  text-decoration: none;
  color: white;
}

.admin-home-nav button {
  background-color: maroon;
  color: white;
  padding: 15px;
  font-size: 21px;
  margin: 10px 0px;
  min-width: 200px;
  border-radius: 25px;
  font-weight: 600;
}

.admin-home-nav button:hover, .admin-home-nav a:hover {
  background-color: white;
  color: maroon;
}
.admin-home-logging button a {
  text-decoration: none;
  color: navy;
}

.admin-home-logging button {
  padding: 10px;
  border-radius: 25px;
  background: white;
  color: navy;
  font-weight: 900;
  font-size: 1.5rem;
  margin: 20px 0px;
  width: 200px;
}

.admin-home-logging button a:hover, .admin-home-logging button:hover {
  color: white;
  background-color:navy;
}

.admin-hub .section__title {
  margin-top: 20px;
}

.news-item-page-text-box {
  margin: auto;
  margin-top: 50px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  text-align: left;
}

.news-item-page-text-box h2,
/* .news-item-page-text-box img, */
.news-item-page-text-box h4,
.news-item-page-text-box button { /* Added button to this group */
  padding: 20px;
}

.news-item-page-text-box button {
  padding: 15px;
  border: none;
  background-color: lightGrey;
  box-shadow: 4px 4px 8px 2px rgba(0,0,0,0.2);
  border-radius: 25px;
}


.news-item-page-text-box p {
  padding: 10px;
  font-family: "Baskervville", serif;
  font-size: 18px;
  text-align: left;
}

.news-item-page-text-box h2,
.news-item-page-text-box h4 {
  color: maroon;
  text-align: left;
}

.news-item-page-text-box h2 {
  padding-bottom: 30px;
  border-bottom: 1px solid lightGrey;
  font-weight: bold; /* Consolidated font-weight */
}

.news-item-page-text-box h4 {
  font-weight: 600; /* Adjusted here to be more specific */
}

.news-item-image {
  width: 800px;
  height: 450px;
  margin: 10px auto;
}

.news-item-row .card-title {
  text-align: left;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 49px;
}

@media only screen and (max-width: 765px) {
  .news-item-row .card-title {
    min-height: 25px;
    font-size: 18px !important;
  }
  .news-page-container .news-card {
    margin-bottom: 10px;
  }
  .news-page-container .news-subheading h1 {
   margin-top: 60px;
   font-size: 3rem;
  }

  .news-card-body {
    height: 100px;
  }
  .news-page-container .news-card-body span { 
    height: 20px;
    width: 100%;
    position: relative;
    font-size: 12px;
  }

  .about-us-page-hidden-col, .staff-and-coaching-hero {
    display: none;
  }

  .col2 {
    padding-top: 80px;
  }

  .news-item-image {
    width: 100%;
    height: auto;
    margin: 10px auto;
  }
}

/* @media screen and (min-width: 700px) {
  .news-most-rec {
      display: none !important;
  }
} */
/* .partner-page-container .partner-sections {
  background-color: lightGrey;
} */

.partner-page-container .testimoial-image img {
  padding: 10px;
}

.partner-page-container .partner-section-title {
  margin-top: 10px;
}

.partner-page-container .partner-logo-img {
  padding: 100px;
}

.partner-page-container .partner-logo-img:hover {
  transform: scale(1.2);
}

.partner-page-container .partner-section-title h1 {
  font-size: 5rem;
  /* font-weight: 100; */
}

.partners-patrons-contact {
  margin-top: 30px 0px;
}

@media only screen and (max-width: 765px) { 
  .shop-items-containers .shop-items-containers-col button {
    margin-bottom: 20px;
  }

  .shop-items-containers-col-box {
    border: 1px solid maroon;
    margin-bottom: 20px;
    background-color: maroon;
  }

  .shop-items-containers-col h1 {
    margin-bottom: 0px;
  }
}

.staff-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.staff-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px;
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
  box-shadow: 3px 4px 4px 2px rgba(0,0,0,0.1);
  border-radius: 8px;
  background-color: #ffffff;
  width: calc(50% - 20px);
  box-sizing: border-box;
  flex: 1 0 auto;
}

.staff-card:first-child, .staff-card:nth-child(2) {
  margin-top: 20px;
}

.staff-photo {
  width: 100px; /* Adjust if necessary */
  height: 100px; /* Adjust if necessary */
  margin-top: 20px; /* Added margin-top to replace padding */
  object-fit: cover;
  /* border-radius: 50%; */
}

.staff-info {
  width: 100%; /* This will take the full width of the card */
  background-color: maroon; /* Maroon background for the entire bottom half */
  color: white; /* White text */
  border-radius: 0 0 8px 8px; /* Rounded corners at the bottom */
  flex-grow: 1; /* Make the .staff-info grow to fill available space */
  display: flex; /* Added display flex to .staff-info */
  flex-direction: column; /* Make content of .staff-info stack vertically */
  justify-content: space-between;
}

.staff-info h3, .staff-info p, .staff-email a {
  padding: 5px 5px; 
  margin: 0;
  color: white;
  font-size: 1em;
}

.staff-email a {
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 600;
}

/* Hide the entire component on screen sizes larger than 660px */
@media (min-width: 661px) {
  .staff-grid {
    display: none;
  }
}


