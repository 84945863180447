/* General Styles */
.competition-items {
  height: 300px;
  border: 1px solid maroon;
  filter: grayscale(100%);
  margin-bottom: 20px;
}

/* Hover Effects */
.competition-items:hover {
  transform: scale(1.04);
}

/* Element Specific Styles */
.competition-items h4 {
  color: maroon;
  font-size: 42px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Responsive Design - Mobile View */
@media (max-width: 601px) {
  body .card {
    background-clip: none;
    border: none;
  }
  body .card img {
    border-radius: 10px;
  }
}

/* Responsive Design - Tablet and Desktop View */
@media (min-width: 601px) {
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }
}

/* Card Hover Effect */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Other Class Styles */
.card-text {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hp-competitions {
  margin-bottom: 100px;
}

.hp-competitions .modal-body {
  text-align: left;
}
