.squads-hero-image {
  background-color: green;
  opacity: 0.6;
  height: 100vh;
  width: 100vw;
}

.squads-hero-image-layer {
  background-color: navy;

}

.squads-hero-box {
  position: absolute;
  top: 400px;
  left: 100px;
  text-align: left;
  width: 25%;
  color: white;
}

.squads-hero-box h1 {
  font-size: 3.5rem;
  font-weight: 900;
  width: 50%;
  margin-bottom: 30px;
}

.squads-hero-box h4 {
  text-align: left;
}

.squads-info-section .section__title {
  margin-top: 300px;
}

@media only screen and (max-width: 1000px) {
  .squads-info-section .section__title {
    margin-top: 50px;
  }
  .squads-text {
    margin-bottom: 30px;
  }
}

.squads-body-text p {
  width: 80%;
  margin: auto;
  margin-top: 40px;
  float: right;
  padding-right: 30px;
  text-align: left;
  line-height: 25px;
}

.squad-list-section .card {
  margin-bottom: 30px;
}

.squad-item-text-box p {
  text-align: left;
  font-size: 21px;
  width: 80%;
  margin: auto;
  padding: 60px 0px;
}

.sq-list-members {
  width: 80%;
  margin: auto;
}

.squad-item-text-box h4 {
  padding-top: 50px;
  padding-bottom: 20px;
  text-align: left;
  font-weight: 600;
  font-size: 26px;
}

.squad-item-text-box li {
  text-align: left;
  padding: 2px;
}

.squad-list-section a, .squad-list-section a:hover {
  text-decoration: none;
  color: maroon;
}

@media only screen and (max-width: 800px) {
  .squads-hero-image {
    height: auto;
    width: 100vw;
  }

  .squads-hero-image-layer {
    display: none;
  }

  .squads-info-section, .squads-info-section h1 { 
    background-color: maroon;
    color: white;
  }

  .squads-info-section .section__title {
    border-bottom: 5px solid white;
  }

  .squad-list-section {
    overflow: auto;
    display: flex !important;
    flex-wrap: unset !important
  }

  .squad-list-section .card, .squad-list-section .card-title, .squad-item-text-box {
    /* background-color: maroon;
    color: white; */
    font-weight: 600;
  }

  .squad-item-text-box {
    padding-top: 60px;
  }

  .squad-item-text-box p {
    font-size: 16px !important;
    overflow: auto;
    padding: 30px 0px;

  }

  .sq-list-members {
    padding-bottom: 30px;
  }

  .squad-list-section .card img {
    border-radius: 0px;
    height: 160px;
    width: 240px;
  }

  .squad-item-page .mob-banner-title h1 {
    text-align: center;
  }
}
