.navbar {
  font-size: 1.3rem;
  font-family: Open Sans;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 100px;
  width: 100vw;
}

.maroon {
  background-color: maroon;
  color: black;
}

.navbar-nav {
  margin-left: 30px;
}

/* .navbar-brand img {
  height: 85px;
} */

.dropdown-menu {
  background-color: maroon !important;
  opacity: 0.8;
}

.dropdown-item:focus, .dropdown-item:hover {
  background-color: white !important;
  color: maroon !important;
}

.dropdown-menu a {
  color: white;
  opacity: 1;
  font-weight: 600 !important;
}

@media only screen and (max-width: 700px) {
  .navbar-brand img {
    height: 55px;
  }
  .navbar-collapse {
    background-color: maroon;
    height: 100vh;
  }

  .navbar-nav {
    padding-top: 15%;
  }

  .nav-item {
    margin: 35px 0px;
  }
  
}

.nav-img img {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#basic-nav-dropdown {
  color: #fff;
}

.me-auto a {
  font-weight: 600;
}

