.partner-sections {
  height: auto;
  /* background-color: rgba(218, 218, 222); */
}

.partner-logo-img {
  height: 100%;
  width: 100%;
  margin: auto;
  filter: grayscale(10%);
}
.partner-logo-img img {
  height: 80%;
  width: 80%;
  filter: grayscale(10%);
}

.partner-section-title h1 {
  text-align: left;
  position: relative;
  color: maroon;
  font-weight: 900;
  font-size: 3rem;
}

@media only screen and (max-width: 600px) {
  .partner-section-title {
    padding-top: 50px;
  }
  .partner-logo-img {
    margin-bottom: 50px;
  }
  .partner-section-title h1 {
    text-align: left;
    padding-left: 15px;
  }
  .partner-logo-img img {
    height: 120px;
    width: 200px;
    margin-top: 10px;
  }
  .partner-section-title h1 {
    font-size: 2.5rem;
  }
}
