.footer {
  border-top: 2px solid darkred;
  /* margin-bottom: 50px; */
  background-color: rgba(218, 218, 222);
}

.footer-partners-section {
  height: 100px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}


.footer-partners-section img {
  height: 100%;
  width: 100%;
  filter: grayscale(1);
  padding: 20px;
}

@media only screen and (max-width: 765px) {
  .footer-partners-section {
    height: 100px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .footer h1 {
    text-align: left;
    padding-left: 25px;
    font-size: 40px;
  }
}

