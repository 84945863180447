.vacancy-hero-nav {
  background-color: rgba(218, 218, 222);
}

.vacancy-hero-nav-col-box {
  width: 100%;
  padding-bottom: 10px;
}

.vacancy-hero-nav-col {
  width: auto;
  text-align: left;
  padding-top: 40px;
}

.vacancy-hero-nav span {
  font-size: 1.2rem;
  color: maroon;
  text-align: right;
  font-weight: 600;
}

.vacancy-title h1 {
  text-align: left;
  margin: 50px 0px;
  font-weight: 700;
}

.vacancy-hero-nav-col-box button {
  background-color: maroon;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  padding: 5px;
}

.vacancy-hero-nav-col-box a {
  text-decoration: none !important;
  color: white !important;
}

@media only screen and (max-width: 800px) {
  .vacancy-hero-nav-col {
    width: 100vw;
    text-align: left;
    padding: 20px 0px;
    margin-left: 20px;
  }

  .vacancy-hero-nav span {
    font-size: 1rem;
  }

  .vacancy-hero-nav-col-box {
    width: 90%;
    text-align: left;
  }
}

.job-description-container-box a,
.job-description-container-box a:hover {
  color: black;
  text-decoration: none;
}

.job-description-container-box a h4 {
  color: grey;
}

.job-description-container-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin-bottom: 30px;
  padding: 10px 5px;
}

.job-description-container-box:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.job-description-container {
  width: 80%;
  text-align: left;
  float: left;
  height: 100%;
  padding-left: 10px;
}

.job-description-container-image {
  width: 20%;
  float: right;
  height: 100%;
}

.job-description-container-image img {
  position: relative;
  width: 100%;
}

.job-description-container span,
.job-description-container h5,
.job-description-container h3,
.job-description-container h4 {
  text-align: left;
  padding-left: 10px;
}

.job-description-container h5:nth-child(0) {
  color: gray;
}

.vac .staff-and-coaching-hero-text,
.vac .vacancy-hero-nav-col-box {
  text-align: left;
}

@media only screen and (max-width: 800px) {
  .job-description-container span,
  .job-description-container h5,
  .job-description-container h3,
  .job-description-container h4 {
    padding-left: 0px;
  }
  .job-description-container {
    width: 100%;
  }

  .job-description-container-box {
    margin-bottom: 20px;
  }

  .job-description-container h4 {
    font-size: 18px;
  }

  .job-description-container h3 {
    font-size: 22px;
  }

  .job-description-container h5 {
    font-size: 16px;
  }
  .job-description-container span {
    font-size: 14px;
  }
  .vac .row {
    margin-right: 0px !important;
  }
}
