/* General Styles */
.bucs-section {
  background-color: white;
  height: auto;
}

.bucs-section span {
  font-size: 21px;
  color: maroon;
  font-weight: 600;
  text-align: left;
  /* padding-top: 50px; */
}

.bucs-section-img {
  max-width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
}

.bucs-section img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: auto;
}

.bucs-section-text {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.bucs-section .carousel-btn {
  border: 2px solid maroon;
  color: white;
  margin-top: 20px;
  margin-bottom: 0%;
  width: 250px;
}

.bucs-section .carousel-btn span {
  background-color: maroon;
  color: white;
  padding-top: 0px;
  padding-left: 5px;
}

/* Media Queries */
@media only screen and (max-width: 600px) {
  .bucs-section-img {
    height: 110px;
    margin-bottom: 30px;
  }

  .bucs-section img {
    height: 110px;
  }

  .bucs-section-text {
    padding: 30px 2.66rem 0px !important;
  }

  .bucs-section span {
    font-size: 16px;
  }

  .bucs-section .carousel-btn {
    border: none;
  }

  .bucs-section .button-icon {
    display: none;
  }

  .bucs-section .carousel-btn span {
    background-color: #fff;
    color: navy;
    font-weight: 600;
    font-size: 18px;
  }
}

/* Nested Elements */
/* Commented out media query for reference
@media only screen and (max-width: 800px) {
  .bucs-section-text {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }
}
*/
