.mobile-navbar {
  height: 70px;
  width: 100%;
  background-color: none; /* Translusent maroon */
  position: fixed;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  transition: background-color 0.5s; /* Transition for background color change */
}

.mobile-navbar .logo {
  max-height: 54px; /* Adjust as needed */
  z-index: 999;
}

.mobile-navbar.closed {
  height: 70px;
}

.mobile-navbar.scrolled {
  background-color: maroon; /* Maroon background when scrolled */
}

.hamburger {
  cursor: pointer;
  font-size: 40px;
  color: maroon;
  transition: color 0.5s;
  z-index: 999;

}

.hamburger.scrolled {
  color: white;
}

.overlay {
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999; /* Ensure it stays on top */
  background-color: rgba(0,0,0, 0.9); /* Black w/ opacity */
  transition: 0.5s; /* 0.5 second transition effect to slide in the overlay */
}

.overlay-content {
  position: relative;
  top: 20%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: right;
  margin-top: 30px; /* 30px top margin to add some spacing */
  position: absolute;
}

.overlay a {
  text-align: left;
  padding: 8px;
  padding-left: 30px;
  text-decoration: none;
  font-size: 36px; /* Large text */
  color: white; /* Grey color */
  display: block; /* Display block to stack links vertically */
  transition: 0.3s; /* Transition effect for the links */
}

.overlay a:hover, .overlay a:focus, .close-btn:hover {
  color: maroon; /* Light grey hover/focus color */
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px; /* Adjust size as needed */
  cursor: pointer;
  color: white; /* Adjust color as needed */
}

.dropdown {
  position: relative;
  display: block;
}

.dropdown-content {
  display: block;
  position: fixed;
  min-width: 180px;
  border-left: 2px solid maroon;
  z-index: 50;
  right: 0;
  top: 25%;
}

.dropdown-content-high {
  top: 15% !important;
  /* max-height: 70vh; */
  overflow-y: auto;
  min-width: 180px;

}

.dropdown-content-high a {
  font-size: 18px !important;
}

.maroon-text {
  color: maroon;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 24px;
}

.dropdown-content a:hover {background-color: #f1f1f1}
