@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(40px);
  }
  100% {
    transform: translatey(0px);
  }
}

.hero-banner-floating-arrow,
.hero-banner-floating-arrow-caro {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translatey(0px);
  animation: float 1.5s ease-in-out infinite;
  z-index: 100;
}

@media only screen and (max-width: 1200px) {
  .hero-banner-floating-arrow-caro {
    display: none;
  }
}

.staff-and-coaching-hero-text span {
  font-size: 1rem;
  opacity: 1;
  color: #fff;
  z-index: 1000;
  position: relative;
  font-weight: 700;
  text-align: left;
}
