
.testimonial-item {
  text-align: left;
}
.testimonial-item span {
  font-size: 18px;
  text-align: left;
  margin-bottom: 20px;
  color: gray;
  padding-right: 20px;
  padding-left: 30px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.testimonial-item h4 {
  font-size: 21px;
  font-weight: 700;
  padding-bottom: 20px;
  text-align: left;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  margin-top: 5px;
} 

.testimonial-item {
  padding: 20px 0px;
  /* border: 2px solid lightblue; */
  border-radius: 25px;
  margin: 20px 0px;
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
  transition: 0.3s;
}



.testimoial-image img {
  margin-top: 20px;
  height: 200px;
  width: 200px;
  /* border-radius: 100px; */
  background-color: white;
  /* border: 5px solid lightblue; */
}

.partner-sections .testimoial-image img {
  height: 180px;
  width: 180px;
}

.testimoial-image {
  margin: auto;
  /* border: 4px solid lightblue; */
  /* background-color: black; */
}
