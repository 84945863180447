/* Base Styles */
.getInvolved {
  position: relative;
  margin-bottom: 60px;
}

.getInvolved img {
  height: 400px;
  width: 100%;
  opacity: 0.5;
}

.getInvolved-layer {
  background-color: black;
}

.getInvolved-text {
  position: absolute;
  top: 100px;
  left: 30px;
  color: white;
  text-align: left;
}

.getInvolved-text h4,
.getInvolved-text h4 {
  font-weight: 900;
  font-size: 2.5rem;
  text-align: left;
}

.getInvolved-text h6 {
  font-size: 2rem;
}

.getInvolved-text button {
  border: 2px solid white;
  padding: 15px;
  border-radius: 25px;
  background-color: maroon;
  color: white;
  font-size: 1.1rem;
  font-weight: 900;
}

/* Media Queries */
@media only screen and (max-width: 800px) {
  .getInvolved-text h4 {
    font-size: 2rem;
  }

  .getInvolved-text h6 {
    font-size: 1.5rem;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .getInvolved {
    background-color: maroon;
    height: auto;
    margin-bottom: 0px;
  }

  .hp-competitions {
    margin-bottom: 0px;
  }

  .getInvolved-layer img {
    display: none;
  }

  .getInvolved-text {
    color: white;
    position: initial;
  }

  .getInvolved-text h4 {
    font-size: 21px;
    text-align: center;
    padding: 20px 0px;
    color: #fff;
  }

  .getInvolved-text h6 {
    font-size: 16px;
    text-align: left;
    padding: 30px 2.66rem 20px;
    font-weight: 600;
  }

  .getInvolved-text a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

  .getInvolved-text button {
    font-size: 12px;
    padding: 0px 20px;
    height: 50px;
    margin-bottom: 20px;
  }
}
