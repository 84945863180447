.get-invovled-page-text {
  padding-top: 100px;
  padding-bottom: 30px;
}

.get-invovled-page-text-span {
  text-align: left;
  font-size: 18px;
}

.get-involved-buttons {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}

.get-involved-buttons button {
  margin: auto;
  width: auto;
  height: 80px;
}

.get-involved-buttons a,
.get-involved-buttons a:hover {
  text-decoration: none;
  color: white;
}

.button-19 {
  background-color: maroon;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: din-round, sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter 0.2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.button-19:after {
  background-clip: padding-box;
  background-color: maroon;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.button-19:main,
.button-19:focus {
  user-select: auto;
}

.button-19:hover:not(:disabled) {
  filter: brightness(1.1);
}

.button-19:disabled {
  cursor: auto;
}

.get-involved-buttons-text {
  text-align: left;
  margin-bottom: 30px;
}

.get-involved-parallax {
  filter: grayscale(100%);
}

@media only screen and (max-width: 1200px) {
  .get-invovled-page-text {
    padding: 30px 30px;
  }
}

@media only screen and (max-width: 700px) {
  .get-invovled-page-text {
    padding-top: 80px !important;
    background-color: maroon;
    color: white;
  }

  .get-involved-buttons {
    background-color: maroon;
    margin-bottom: 0px;
    padding-bottom: 50px;
  }

  .get-involved-buttons button {
    border: 1px solid white;
  }

  .mob-banner-title h1 {
    text-align: left;
    padding-top: 20px;
  }

  .get-invovled-page-text-span {
    font-size: 16px;
  }
}

@media only screen and (min-width: 700px) {
  .mob-banner-title {
    display: none;
  }
}