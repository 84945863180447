.carousel-image {
  background-color: green;
  box-shadow: inset 0 0 0 1000px red;
  height: 100vh;
}

.layer {
  background-color: #0047ab;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.carousel-text h3 {
  opacity: 100%;
  font-size: 4.5rem;
  font-weight: 600;
  margin-bottom: 20px;
  margin-right: 100%;
  width: 100%;
  text-align: left;
}


.carousel-btn a, .carousel-btn a:hover {
  color: white;
}
.carousel-btn {
  width: 240px;
  height: 60px;
  color: white;
  font-weight: 800;
  letter-spacing: 0px;
  margin: auto;
  margin-bottom: 10%;
  margin-right: 100%;
  position: relative;
}

.carousel-btn span {
  float: left;
  width: 80%;
  height: 100%;
  background-color: maroon;
  font-size: 1.5rem;
  line-height: 55px;
}

.button-icon {
  float: right;
  width: 20%;
  height: 100%;
  background-color: white;
}

.carousel-caption-text {
  position: relative;
  top: 0 !important;
  left: 0 !important;
}

.carousel-item {
  height: 100vh;
}

.button-icon svg { 
  height: 60px;
  width: 50px;
}

@media only screen and (max-width: 1200px) {
  .carousel-item {
    height: auto;
  }
}

/* @media only screen and (max-width: 900px) {
  .carousel-text h3 {
    font-size: 3.5rem;    
  }
  .carousel-btn span {
    font-size: 1.2rem;    
  }
} */

@media only screen and (max-width: 600px) {
  .carousel-text h3 {
    font-size: 3rem;    
  }
  .carousel-btn span {
    font-size: 1.5rem;    
  }
  .carousel-btn {
    height: 50px;
  }
  .button-icon svg { 
    height: 50px;
    width: 45px;
  }
  
}


.carousel-btn:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}




