.staff-and-coaching-img {
  height: 100vh;
  width: 100%;
}

.layer-staff {
  background-color: #0047ab;
  opacity: 0.45;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

/* .layer-staff-full {
  background-color: #0047ab;
  opacity: 0.45;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
} */

.staff-and-coaching-hero {
  position: relative;
}
.staff-and-coaching-hero-text {
  position: absolute;
  bottom: 50%;
  left: 15%;
  width: 500px;
}

.staff-and-coaching-hero-text h1 {
  font-size: 3.5rem;
  opacity: 1;
  color: #fff;
  z-index: 1000;
  position: relative;
  font-weight: 700;
  text-align: left;
}

@media only screen and (max-width: 800px) {
  .staff-and-coaching-hero-text h1 {
    font-size: 2.5rem;
    padding-right: 50px;
  }
  .staff-and-coaching-hero-text {
    width: auto;
    text-align: left;
    left: 5%;
  }
}

.staff-football {
  margin-top: 25px;
  margin-bottom: 25px;
}

.staff-and-coaching .react-parallax-bgimage {
  filter: grayscale(100%) !important;
}

.staff-section-box {
  min-height: 400px;
}
.staff-section-box:hover {
  cursor: pointer;
}

.staff-section-box img {
  height: 80%;
  width: 100%;
}

.staff-section-box h2 {
  background-color: navy;
  color: white;
  height: 20%;
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 10px;

  text-align: left;
}

.staff-section-box p {
  background-color: navy;
  color: white;
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 80%;
  margin: auto;
  text-align: left;
}

.staff-and-coaching table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.staff-and-coaching td,
.staff-and-coaching th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.staff-and-coaching tr:nth-child(even) {
  background-color: #dddddd;
}

.staff-and-coaching table {
  margin-top: 25px;
}

@media only screen and (max-width: 700px) {
  .staff-email-text {
    display: none;
  }
  .staff-and-coaching td, .staff-and-coaching th {
  font-size: 14px;
  }
}

@media only screen and (min-width: 701px) {
  .staff-email-link {
    display: none;
  }
}


