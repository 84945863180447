.box-news {
  margin-top: 50px;
  margin-bottom: 20px;
  color: maroon;
}

.box-news .card-title {
  color: maroon;
}

.box-news h1 {
  color: navy;
  font-size: 48px;
  border-bottom: 5px solid darkred;
  text-align: left;
  padding-left: 30px;
  font-weight: 700;
}

.squad-list-section .card {
  width: fit-content !important;
}

.card-img-mob {
  height: 160px;
  width: 240px !important;
  aspect-ratio: 3 / 2;
  object-fit: cover;
}

@media only screen and (max-width: 765px) {
  .box-news h1 {
    font-size: 32px;
    padding-bottom: 10px;
    border-bottom: 3px solid darkred;
    text-align: left;
    margin-top: 30px;
  }

  .news-item-row .card-title {
    text-align: left;
    color: black;
  }

  .card-img-mob {
    height: 100px;
    width: 150px;
    aspect-ratio: 3 / 2;
    object-fit: cover;
  }
}

.article-container {
  padding: 10px;
}

.article-container a {
  text-decoration: none;
}

.article-container a .card-title {
  color: black;
}

.subscribe-box {
  color: maroon;
  height: auto;
  display: flex;
  flex-direction: column;
}

.subscribe-box form {
  display: flex;
  flex-direction: column;
}

.subscribe-box h4 {
  padding-top: 2%;
  font-weight: 700;
}

.subscribe-box input,
.subscribe-box button {
  width: 300px;
  margin: auto;
  padding: 10px;
  margin-top: 10px;
  border-top: none;
  border-right: none;
  border-left: none;
}

.subscribe-box button {
  margin-bottom: 30px;
}

.subscribe-box button {
  border: 1px solid white;
  background-color: maroon;
  color: white;
  border-radius: 25px;
  width: 200px;
  font-weight: 700;
}

.subscribe-box button:hover {
  background-color: white;
  color: maroon;
  border: 2px solid maroon;
}

.view-all-link a {
  color: maroon;
  text-decoration: none;
}

.view-all-link a span:hover {
  color: maroon;
  transform: scale(1.5);
}

.news-title {
  position: absolute;
  top: 300px;
  width: 100%;
}

.news-item-row .card {
  margin-bottom: 30px;
}

.news-item-row .card a, .news-item-row .card a:hover {
  text-decoration: none;
  color: maroon;
  font-weight: 700;
}

.news-subheading h1 {
  text-align: left;
  margin: 30px 0;
  font-weight: 800;
  font-size: 4rem;
  color: maroon;
  border-bottom: 2px solid navy;
}

@media only screen and (max-width: 765px) {
  .news-subheading h1 {
    margin: 30px 0 0 0;
  }
  .article-container {
    width: 100%;
    overflow: auto;
    display: flex !important;
    flex-wrap: unset !important;
  }

  .article-container .card-title {
    font-size: 16px !important;
  }

  .view-all-link a, .view-all-link a span svg {
    color: navy;
    font-weight: 600;
  }

  .view-all-link {
    margin-bottom: 15px;
  }
  .subscribe-box {
    background-color: maroon;
    color: white;
    padding: 30px 0px;
  }

  .subscribe-box form input {
    border-radius: 15px;
    margin-bottom: 15px;
  }
}
